/* Global imports */
import React from "react";

/* Local imports */
const CarouselControl = ({ next, previous, goToSlide, ...rest }) => {
  if (rest.carouselState.totalItems <= 1) {
    return null;
  }
  return (
    <div className="frient-carousel-control">
      <div
        className={
          "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        }
        onClick={previous}
        role="button"
        onKeyDown={previous}
        tabIndex={0}
        aria-label="previous"
      ></div>
      <div
        className={
          "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        }
        onClick={next}
        role="button"
        onKeyDown={next}
        tabIndex={0}
        aria-label="next"
      ></div>
    </div>
  );
};

export default CarouselControl;
